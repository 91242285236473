import SFX from '@pidk/common/src/sfx'
import { isMatchingArray } from '@pidk/common/src/utils/arrays'
import { useState, useEffect } from 'react'
import useSound from 'use-sound'

interface IUseChoice {
  id: string
  correct: any[]
  multiple: boolean
  onChecked: (isCorrect: boolean, { selected }) => void
}

type ChoiceState = 'init' | 'selected' | 'results' | 'voting' | 'feedback'

interface IChoiceReturn {
  state: ChoiceState
  selected: any[]
  actions: any
  attemptCount: number
}

const useChoice = ({ id, correct, multiple, onChecked }: IUseChoice): IChoiceReturn => {
  const [state, setState] = useState<ChoiceState>('init')
  const [selected, setSelected] = useState([])
  const [ attemptCount, setAttemptCount] = useState(0)

  const [clickSFX] = useSound(SFX.click, {
    volume: 0.25
  })
  const [ errorSFX ] = useSound(SFX.negativeBliep)
  const [ correctSFX ] = useSound(SFX.correctAlert)

  const actions = {
    onSelectOption(e, index: number) {
      e.preventDefault()

      // Call soundfx
      clickSFX()

      if (state === 'init' || state === 'selected') {
        let selection = [...selected]
        let i = selection.indexOf(index)

        // If multiple add or delete selected item
        if (multiple) {
          if (i > -1) {
            selection.splice(i, 1)
          } else {
            selection = [...selection, index].sort()
          }
          // Otherwise just switch the selection to current index
        } else {
          selection = [index]
        }
        setSelected(selection)
        if (selection.length > 0) {
          setState('selected')
        } else {
          setState('init')
        }

      }

    },
    checkAnswer(e) {
      e.preventDefault()

      let isCorrect = null

      if (correct.length > 0) {
        isCorrect = isMatchingArray(selected, correct)

        // Play soundfx
        if(isCorrect) {
          correctSFX()
        } else {
          errorSFX()
        }

      }

      onChecked(isCorrect, { selected })
      setState('feedback')

      if(attemptCount > 1) {
        setAttemptCount(0)
      } else {
        setAttemptCount(attemptCount + 1)
      }

    },
    setState(state) {
      setState(state)
    },
    reset() {
      setState('init')
      setSelected([])
    }
  }

  useEffect(() => {
    actions.reset()
  }, [id])

  return {
    state,
    selected,
    actions,
    attemptCount
  }
}

export default useChoice
