import type { FieldValues, ISlide, DeckParsed } from '@pidk/compose/src/types/compose'
import type { Field } from '@pidk/compose/src/types/fields'
import type React from 'react'
import type { CSSObject } from 'styled-components'
export interface ISlideSchema {
  name: string
  key: string
  fields: Field[]
  defaultFieldValues: FieldValues
}

export interface ISlideComponentProps {
  logo: FieldValues | false
  slide: ISlide
  deck?: DeckParsed
  onAreaClick: (id: string) => void
  selectedAreaId?: string
  isEditable: boolean
  full?: boolean
}

export type SlideComponent = React.FC<ISlideComponentProps> & {
  schema: ISlideSchema
}

export enum RendererView {
  OVERVIEW = 'OVERVIEW',
  LOGIN = 'LOGIN',
  THEME = 'THEME',
  DECK = 'DECK'
}

export type ComponentCSSTheme = Record<string, CSSObject>
