/* eslint-disable react/no-unused-prop-types */
import transitions from '@pidk/common/src/lib/transitions'
import { FieldType, MediaType } from '@pidk/compose/src/types/fields'
import type { IFieldSchema } from '@pidk/compose/src/types/fields'
import type { ReactNode } from 'react'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import type { CSSObject } from 'styled-components'
import type { InteractionType } from 'vuurrood-sockets'

import useProjectMediaMap from '../../../hooks/useProjectMediaMap'
import InteractionControls from '../../InteractionControls/InteractionControls'
import { Actions, ActionButton } from './../../common/Actions'
import Feedback from './../../common/Feedback'
import type { IFeedback } from './../../common/Feedback'
import Option, { Image } from './components/Option'
import useChoice from './useChoice'

/*
META TODO:
- add conditional images based on fieldvalue
- variation in option type perhaps (image left right, bottom, top).
- find out how to add the useChoice or socket function, and disable it in the editor
- improve typings
*/

type BlockComponent = React.FC<IChoice> & {
  schema: IFieldSchema
  Styled?: any // @TODO: can we type this?
}

interface IOption {
  label: string
  correct: boolean
  image?: string
}

interface IChoice {
  id: string
  layout: 'horizontal' | 'vertical'
  type: 'text' | 'text-image' | 'image'
  options: IOption[]
  feedback?: IFeedback
  style?: CSSObject
  isHost?: boolean
  onDone?: (interactionData: any) => Promise<void>
  onPopup?: (type: string, component: ReactNode, timeout, closable) => void
}

const Base = styled.form<any>`
  width: 100%;
  max-width: 540px;
`

const Grid = styled.div<any>`
  display: flex;
  flex-direction: ${props => (props.$layout === 'horizontal' ? 'row' : 'column')};
  justify-content: flex-start;
  width: 100%;
  gap: 1rem;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    align-items: center;
  }

  ${Option.Styled} {
      flex: 1 1 auto;
      max-width: ${props => props.$type === 'text' ? 'auto' : '250px'};
  }
`
const calculateVoteResults = (results: number[], amountOptions: number) => {
  let totalVotes = 0
  const votePercentages = new Array(amountOptions).fill(0)
  const votesPerOption = new Array(amountOptions).fill(0)

  results.forEach((value) => {
    votesPerOption[value] += 1
    totalVotes = totalVotes + 1
  })

  votePercentages.map((_: any, index: number) => {
    votePercentages[index] = votesPerOption[index] / totalVotes * 100
  })

  return votePercentages
}

const Choice: BlockComponent = ({
  id,
  layout,
  type,
  options,
  feedback,
  onPopup,
  isHost,
  onDone
}: IChoice) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [interactionResults, setInteractionResults] = useState(null)
  const [votes, setVotes] = useState<any>(null)
  const [isCorrect, setFeedback] = useState(null)
  const images = useProjectMediaMap()

  // TODO: Maybe cleanup
  const correctOptions = options?.map((o, i) => o.correct ? i : -1).filter(i => i !== -1)

  const handleCheckedAnswer = (correct) => {

    setFeedback(correct)

    // TODO: ignore opening dialog when voted results are shown.
    return onPopup('dialog', <Feedback
      isCorrect={correct}
      hasCorrect={correctOptions.length > 0}
      title={feedback.title}
      content={feedback?.content}
      image={feedback?.image}
                             />, null, true)

  }

  // eslint-disable-next-line
  const { state, selected, actions } = useChoice({
    id,
    correct: correctOptions,
    multiple: (correctOptions.length === 1 ? false : true),
    onChecked: handleCheckedAnswer
  })

  useEffect(() => {
    if (!interactionResults) {
      if (votes) {
        setVotes(null)
      }

      return
    }

    setVotes(calculateVoteResults(interactionResults, options.length))
  }, [interactionResults])

  const onShowFeedback = (correct, hasCorrect) => {
    return onPopup('dialog', <Feedback
      isCorrect={correct}
      hasCorrect={hasCorrect}
      title={feedback.title}
      content={feedback?.content}
      image={feedback?.image}
                             />, null, true)
  }

  return (
    <Base onSubmit={(e) => {
      e.preventDefault()

      if (onDone !== undefined) {
        onDone(selected)
        return
      }

      actions.checkAnswer(e)
    }}
    >
      <Grid
        $layout={layout}
        $type={type}
      >
        {options?.map((c, i) => {
          const optionImage = images?.[c.image]

          return (
            <Option
              key={c.label + '' + i}
              onClick={(e: void) => actions.onSelectOption(e, i)}
              label={String.fromCharCode(97 + i).toUpperCase()}
              isSelected={selected.includes(i)}
              isCorrect={correctOptions.length === 0 ? null : correctOptions.includes(i)}
              state={state}
              votes={votes ? votes[i] : null}
            >
              {optionImage && type !== 'text' && (
                <Image>
                  <img
                    alt={c?.label || `Optie ${i}`}
                    src={optionImage?.url}
                  />
                </Image>
              )}
              {type !== 'image' && (
                <span className='label'>
                  {c.label}
                </span>
              )}
            </Option>
          )
        })}
      </Grid>
      <Actions>
        {state === 'selected' && isHost && (
          <ActionButton
            key='action-check'
            {...transitions.fadeUp}
          >
            Bekijk antwoord
          </ActionButton>
        )}

        {state === 'selected' && !isHost && (
          <ActionButton
            key='action-check'
            {...transitions.fadeUp}
          >
            Kies antwoord
          </ActionButton>
        )}

        {(state === 'results' || state === 'feedback') && feedback && isHost && (
          <ActionButton
            key='action-feedback'
            {...transitions.fadeUp}
            onClick={() => onShowFeedback(isCorrect, (interactionResults ? false : (correctOptions.length > 0)))}
          >
            {correctOptions.length === 0 ? 'Bekijk toelichting' : 'Bekijk antwoord'}
          </ActionButton>
        )}

        {isHost && (state === 'init' || state === 'voting') && (
          <InteractionControls
            setInteractionResults={(s) => { setInteractionResults(s), actions.setState('results') }}
            interactionType={'CHOICE' as InteractionType}
            createInteractionCallback={() => actions.setState('voting')}
            interactionData={{
              id,
              layout,
              type,
              options
            }}
          />
        )}
      </Actions>
    </Base>
  )
}

Choice.schema = {
  name: 'Choice',
  key: 'choice',
  defaultFieldValues: {
    type: 'text',
    layout: 'horizontal',
    options: [
      {
        label: 'Optie 1',
        correct: false
      }, {
        label: 'Optie 2',
        correct: false
      }
    ]
  },
  fields: [
    {
      key: 'type',
      type: FieldType.CHOICE,
      label: 'Type',
      options: [
        {
          value: 'text',
          label: 'Text'
        },
        {
          value: 'text-image',
          label: 'Text Image'
        },
        {
          value: 'image',
          label: 'Image'
        }
      ]
    },
    {
      key: 'layout',
      type: FieldType.CHOICE,
      label: 'Layout',
      component: 'button',
      options: [
        {
          value: 'horizontal',
          label: 'Horizontal'
        },
        {
          value: 'vertical',
          label: 'Vertical'
        }
      ]
    },
    {
      key: 'options',
      type: FieldType.REPEATER,
      label: 'Options',
      collapsible: false,
      primaryKey: 'label',
      button: 'Add option',
      fields: [
        {
          key: 'label',
          type: FieldType.TEXT,
          label: 'Label'
        }, {
          key: 'correct',
          type: FieldType.BOOLEAN,
          label: 'Correct'
        }, {
          key: 'image',
          type: FieldType.MEDIA,
          label: 'Image',
          maxFiles: 1,
          mediaType: MediaType.IMAGE,
          conditional: [{
            field: 'type',
            operator: '!=',
            value: 'text'
          }]
        }
      ]
    },
    {
      key: 'feedback',
      type: FieldType.GROUP,
      label: 'Feedback',
      primaryKey: 'content',
      fields: [...Feedback.schema.fields]
    }
  ]
}

Choice.Styled = Base

export default Choice
