import type { FieldHookConfig } from 'formik'
import { useField } from 'formik'

import Select from './../Form/components/Select'

interface IInputSelect {
  // type: string
  // readOnly?: boolean
  children: any
  autoFocus?: boolean
}

const InputSelect = (props: IInputSelect & FieldHookConfig<string>) => {
  const [{
    onBlur,
    value,
    name,
    onChange
  }] = useField(props)

  return (
    <Select
      name={name}
      onChange={onChange}
      value={value}
      onBlur={onBlur}
      placeholder={props.placeholder}
      autoFocus={props.autoFocus}
    >
      {props.children}
    </Select>
  )
}

export default InputSelect
