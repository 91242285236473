export const isMatchingArray = (arr1, arr2) => {

    // Check if the arrays are the same length
  if (arr1.length !== arr2.length) return false

  // Check if all items exist and are in the same order
  for (var i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) return false
  }

  // Otherwise, return true
  return true

}

export const isMatchingArrayDifferences = (arr1, arr2) => {

  let output = []

  // Check if all items exist and are in the same order
  for (var i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      output[i] = arr1[i]
    }
  }

  // Output the differences
  return output

}

export const shuffle = (arr) => {
  if (arr.length === 1) {return arr};
  const rand = Math.floor(Math.random() * arr.length)
  return [arr[rand], ...shuffle(arr.filter((_, i) => i != rand))]
}

export default isMatchingArray
