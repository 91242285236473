import type { FieldHookConfig } from 'formik'
import { useField } from 'formik'
import React from 'react'

import Input from './../Form/components/Input'

interface IInputText {
  type: string
  readOnly?: boolean
  autoFocus?: boolean
}

const InputText = (props: IInputText & FieldHookConfig<string>) => {
  const [{
    onBlur,
    value,
    name,
    onChange
  }] = useField(props)

  // TODO: make me
  //  const hasError = meta?.error && meta?.touched

  return (
    <Input
      name={name}
      onChange={onChange}
      type={props.type}
      value={value}
      onBlur={onBlur}
      placeholder={props.placeholder}
      autoFocus={props.autoFocus}
      readOnly={props.readOnly}
    />
  )
}

export default InputText
