export const theme = {
    colors: {
        transparent: 'transparent',
        primary: '#dd2727',
        secondary: '#e7d618',
        tertiary: '#b01c1c',
        light: '#fdf7f7',
        lightest: '#fafafb',
        white: '#ffffff',
        black: '#000000',
        success: '#28DF99',
        error: '#FF5151',
        custom: [
            '#ffffff'
        ]
    },
    fonts: {
        fontFamily: {
            body: 'Kiwi Maru',
            heading: 'Alkalami',
            buttons: 'Fuzzy Bubbles'
        },
        fontSize: {
            xs: '0.75rem',
            sm: '0.875rem',
            base: '1rem',
            lg: '1.125rem',
            xl: '1.25rem',
            '2xl': '1.5rem',
            '3xl': '2.25rem',
            '4xl': '3rem',
            '5xl': '3.5rem'
        }
    },
    nav: {
        backgroundColor: 'tertiary',
        color: 'secondary',
        actionBackgroundColor: 'secondary'
    },
    richText: {
        small: {
            fontSize: 10
        },
        pretitle: {
            fontSize: 14
        },
        'default': {
            fontSize: 14
        },
        listItem: {
            fontSize: 14
        },
        lead: {
            fontSize: 24
        },
        subtitle: {
            fontSize: 32
        },
        title: {
            fontSize: 48
        },
        'super': {
            fontSize: 64
        }
    },
    button: {
        base: {
            borderRadius: 'sm',
            backgroundColor: 'secondary',
            color: 'primary'
        },
        hover: {
            backgroundColor: 'light',
            color: 'secondary'
        }
    },
    card: {
        base: {
            borderRadius: 'sm',
            backgroundColor: 'white',
            color: 'primary'
        },
        hover: {
            backgroundColor: 'light',
            color: 'black'
        }
    },
    option: {
        base: {
            borderRadius: '0.125rem',
            backgroundColor: 'white',
            color: 'primary'
        },
        badge: {
            backgroundColor: 'secondary',
            color: 'white'
        },
        hover: {
            outline: '2px solid #7579E7',
            backgroundColor: 'light',
            color: 'secondary'
        }
    },
    connect: {
        dropzone: {
            backgroundColor: false,
            color: 'secondary'
        },
        option: {
            'default': {
                backgroundColor: 'secondary',
                color: 'primary'
            },
            done: {
                backgroundColor: 'white',
                color: 'primary'
            }
        }
    },
    sort: {
        dropzone: {
            backgroundColor: false,
            color: 'secondary'
        },
        option: {
            'default': {
                backgroundColor: 'secondary',
                color: 'tertiary'
            },
            done: {
                backgroundColor: 'secondary',
                color: 'primary'
            }
        }
    },
    dropzone: {
        backgroundColor: 'primary',
        color: 'secondary'
    },
    dragzone: {
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.2) 0px 18px 36px -18px inset'
    },
    screen: {
        backgroundColor: 'primary',
        color: 'secondary'
    },
    timer: {
        backgroundColor: 'secondary',
        color: 'white'
    },
    marker: {
        backgroundColor: 'primary',
        color: 'white'
    },
    memory: {
        back: {
            backgroundColor: 'secondary',
            color: 'tertiary'
        },
        front: {
            backgroundColor: 'tertiary',
            color: 'lightest'
        }
    }
}

export default theme
