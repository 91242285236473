import * as Icons from '@pidk/common/src/components/Icons'
import * as React from 'react'
import styled from 'styled-components'

const Component = styled.div``

const VisibilityIndicator = ({ isVisible }: { isVisible: boolean }) => {
  const IconComponent = isVisible
    ? Icons.EyeOpenIcon
    : Icons.EyeNoneIcon

  return (
    <Component>
      <IconComponent />
    </Component>
  )
}

export default VisibilityIndicator
