import type { DeckParsed, ISlide, Project } from '@pidk/compose/src/types/compose'
import merge from 'ts-deepmerge'

import defaultTheme from '../theme/default'

export const getCurrentDeck = (project: Project, currentDeckId: string): DeckParsed | undefined => {
  return project.decks.find(d => d.id === currentDeckId)
}

export const getCurrentSlide = (project: Project, currentDeckId: string, currentSlideId: string): ISlide | undefined => {
  const currentDeck = getCurrentDeck(project, currentDeckId)

  if (currentDeck === undefined) {
    return undefined
  }

  return currentDeck.slides.find(s => s.id === currentSlideId)
}

// TODO: this should look at the project.fields and render the styles
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getProjectTheme = (project: Project) => {
  return {
    colors: {},
    fonts: {
      fontFamily: {
        heading: 'Avenir'
      }
    }
  }
}

export const getProjectMedia = (project: Project, mediaId: string | string[]) => {
  if (Array.isArray(mediaId)) {
    return mediaId.map(mId => project.media?.find(m => m.id === mId))
  }

  return [project.media.find(m => m.id === mediaId)]
}

export const getProjectThemeColor = (colors: string[], color: string) => {

  // Return the colors map with dot notation
  const colorName = color
    ?.split('.')
    ?.reduce((o, i) => o[i], colors)

  return colorName || colors['primary']
}
export const getProjectWithDefaultTheme = (project: Project): Project => {
  project.theme = merge(defaultTheme, project?.theme || {})
  return project
}
