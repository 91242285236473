import { ErrorMessage } from 'formik'
import * as React from 'react'
import styled from 'styled-components'

const Component = styled.div`
  & + & {
    margin-top: ${props => props.theme.spacing[2]};
  }
`
const Content = styled.div`
  display: flex;
  align-items: center;
  gap:  ${props => props.theme.spacing[1]};
`
const Label = styled.div`
  display: block;
  font-family: ${props => props.theme.fonts.fontFamily.body};
  margin-bottom: ${props => props.theme.spacing[1]};
  font-weight: bold;
  font-size: ${props => props.theme.fonts.fontSize['sm']};
  color: ${props => props.theme.colors.black};
`
const Validation = styled.div`
  ${props => ({
    color: props.theme.colors.error[500] || props.theme.colors.error,
    fontSize: props.theme.fonts.fontSize['sm'],
    lineHeight: 1.3,
    marginTop: props.theme.spacing[1]
  })}
`

interface IFormField {
  name: string
  label?: string
  children: any
}

// TODO: remove me, temporary fix since React 18 upgrade
const FixedErrorMessage = ErrorMessage as any

const FormField = ({ name, label, children }: IFormField) => {
  return (
    <Component>
      {label && (
        <Label>
          {label}
        </Label>
      )}
      <Content>
        {children}
      </Content>
      <Validation>
        <FixedErrorMessage name={name} />
      </Validation>
    </Component>
  )
}

export default FormField
