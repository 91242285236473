export const transitions = {
  'none': {

  },
  'default': {
    initial: {
      y: 40,
      opacity: 0
    },
    animate: {
      y: 0,
      opacity: 1
    },
    exit: {
      y: -40,
      opacity: 0
    }
  },
  'fadeIn': {
    initial: { opacity: 0, scale: 0.99 },
    animate: { opacity: 1, scale: 1 },
    exit: { opacity: 0, scale: 0.99 }
  },
  'fadeUpAndDown': {
    initial: {
      y: 20,
      opacity: 0
    },
    animate: {
      y: 0,
      opacity: 1
    },
    exit: {
      y: 20,
      opacity: 0
    },
    transition: { type: 'stiff' }
  },
  'fadeUp': {
    initial: {
      y: 40,
      opacity: 0
    },
    animate: {
      y: 0,
      opacity: 1
    },
    exit: {
      y: 40,
      opacity: 0
    }
  },
  'fadeDown': {
    initial: {
      y: -20,
      opacity: 0
    },
    animate: {
      y: 0,
      opacity: 1
    },
    exit: {
      y: -20,
      opacity: 0
    }
  },
  'fadeLeft': {
    initial: {
      x: -50,
      opacity: 0
    },
    animate: {
      x: 0,
      opacity: 1
    },
    exit: {
      x: -50,
      opacity: 0
    }
  },
  'fadeRight': {
    initial: {
      x: 50,
      opacity: 0
    },
    animate: {
      x: 0,
      opacity: 1
    },
    exit: {
      x: 50,
      opacity: 0
    }
  },
  'rotateIn': {
    initial: {
      y: 0,
      opacity: 0,
      rotate: 0,
      transformOrigin: '50% 55%'
    },
    animate: {
      y: 0,
      opacity: 1,
      rotate: 0,
      transition: {
        ease: 'easeInOut',
        delay: 1
      }
    },
    exit: {
      y: -100,
      opacity: 0,
      rotate: -95,
      x: -250,
      transition: {
        ease: 'easeInOut',
        delay: 2.5
      }
    }
  },
  'flipIn': {
    initial: {
      y: 0,
      opacity: 0,
      rotate: -20,
      rotateY: 90,
      scale: 0.95,
      transformOrigin: '50% 55%'
    },
    animate: {
      y: 0,
      opacity: 1,
      rotate: 0,
      rotateY: 0,
      scale: 1,
      transition: {
        ease: 'easeInOut',
        delay: 0.7
      }
    },
    exit: {
      y: -100,
      opacity: 0,
      rotate: -95,
      x: -250,
      transition: {
        ease: 'easeInOut',
        delay: 0.1
      }
    },
    exitLeft: {
      y: -100,
      opacity: 0,
      rotate: -95,
      x: -250,
      transition: {
        ease: 'easeInOut',
        delay: 0.1
      }
    },
    exitRight: {
      y: -100,
      opacity: 0,
      rotate: 95,
      x: 250,
      transition: {
        ease: 'easeInOut',
        delay: 0.1
      }
    },
    winner: {
      scale: 1.1,
      rotate: 4,
      transformOrigin: '50% 55%',
      transition: {
        ease: 'easeInOut',
        duration: 0.1
      }
    },
    hover: {
      scale: 1.1,
      rotate: -1,
      transformOrigin: '50% 55%'
    },
    tap: {
      scale: 0.95,
      rotate: 1,
      transformOrigin: '50% 55%'
    }
  },
  'bounceUp': {
    initial: {
      y: 0,
      opacity: 0,
      scale: 1,
      transformOrigin: '50% 50%'
    },
    animate: {
      y: [200, 50, 0, 0],
      opacity: [0, 1, 1],
      scale: [0, 0, 1.15, 1],
      transformOrigin: '50% 50%'
    }
  },
  'scaleUp': {
    initial: {
      y: 0,
      x: -50,
      opacity: 0,
      scale: 0.97,
      transformOrigin: '50% 50%'
    },
    animate: {
      y: 0,
      x: 0,
      opacity: 1,
      scale: 1,
      transformOrigin: '50% 50%'
    }
  }
}

export type TransitionProps = keyof typeof transitions;

export default transitions
