import * as React from 'react'
import { useEffect } from 'react'
import styled from 'styled-components'

import RichText from '../../RichText'

const Component = styled.div``

interface ICheckoutOutroProps {
  outro: {
    title: string
  }
  isSubmitting: boolean
  onRender: () => Promise<void>
}

const CheckoutOutro = ({ outro, isSubmitting, onRender }: ICheckoutOutroProps) => {
  useEffect(() => {
    onRender()
  }, [])

  return (
    <Component>
      {isSubmitting ? (
        <div>
          Verzenden...
        </div>
      ) : (
        <div>
          {outro && outro?.title ? (
            <RichText
              align='center'
              content={[
                    {
                      format: 'subtitle',
                      text: outro.title
                    }
                  ]}
            />
            ) : (
              <RichText
                align='center'
                content={[
                    {
                      format: 'subtitle',
                      text: 'Bedankt!'
                    }
                  ]}
              />
            )}
        </div>
      )}
    </Component>
  )
}

export default CheckoutOutro
