import type { IFieldSchema } from '../../types/fields'
import { FieldType, MediaType } from '../../types/fields'

const ActionPropSchema: IFieldSchema = {
  name: 'Action',
  key: 'actions',
  defaultFieldValues: {},
  fields: [
    {
      key: 'type',
      type: FieldType.CHOICE,
      label: 'Type',
      options: [
        {
          value: '',
          label: '-'
        },
        {
          value: 'link',
          label: 'Link'
        },
        {
          value: 'slide',
          label: 'Slide'
        },
        {
          value: 'video',
          label: 'Video'
        },
        {
          value: 'image',
          label: 'Image'
        },
        {
          value: 'popup',
          label: 'Popup'
        }
      ]
    },
    {
      key: 'url',
      type: FieldType.TEXT,
      label: 'Url',
      conditional: [{
        field: 'type',
        operator: '==',
        value: 'link'
      }]
    },
    {
      key: 'slide',
      type: FieldType.SLIDE,
      label: 'Slide',
      instructions: 'Link to other slide in this project',
      conditional: [{
        field: 'type',
        operator: '==',
        value: 'slide'
      }]
    },
    {
      key: 'url',
      type: FieldType.TEXT,
      label: 'Vimeo Mp4 Url',
      instructions: 'Copy paste mp4 url from vimeo',
      conditional: [{
        field: 'type',
        operator: '==',
        value: 'video'
      }]
    },
    {
      key: 'youtube',
      type: FieldType.TEXT,
      label: 'Youtube ID',
      instructions: 'Paste Youtube ID here. Leave rest empty.',
      conditional: [{
        field: 'type',
        operator: '==',
        value: 'video'
      }]
    },
    {
      key: 'image',
      type: FieldType.MEDIA,
      label: 'Image',
      maxFiles: 1,
      mediaType: MediaType.IMAGE,
      conditional: [{
        field: 'type',
        operator: '==',
        value: 'image'
      }]
    },
    {
      key: 'popup',
      type: FieldType.GROUP,
      label: 'Popup',
      fields: [
        {
          key: 'align',
          type: FieldType.CHOICE,
          label: 'Align',
          component: 'button',
          options: [
            {
              value: 'left',
              label: 'Left'
            },
            {
              value: 'center',
              label: 'Center'
            },
            {
              value: 'right',
              label: 'Right'
            }
          ]
        },
        {
          key: 'title',
          type: FieldType.TEXT,
          label: 'Title'
        },
        {
          key: 'image',
          type: FieldType.MEDIA,
          label: 'Image',
          maxFiles: 1,
          mediaType: MediaType.IMAGE
        },
        {
          key: 'content',
          type: FieldType.TEXT,
          label: 'Content',
          instructions: 'Bold (**...**), Italic (*...*), Accent (##...##)'
        }
      ],
      conditional: [{
        field: 'type',
        operator: '==',
        value: 'popup'
      }]
    }
  ]
}

export default ActionPropSchema
