import { theme as defaultTheme } from '@pidk/common/src/lib/tailwind'

import { theme as sintTheme } from './sint'
import { theme as unicefTheme } from './unicef'

export const getBorderRadius = (key: keyof typeof defaultTheme.borderRadii) => {
  return defaultTheme.borderRadii[key] || defaultTheme.borderRadii.sm
}

export const borderRadii = Object.keys(defaultTheme.borderRadii)

const theme = {
  colors: {
    transparent: 'transparent',
    primary: defaultTheme.colors.primary[500],
    secondary: defaultTheme.colors.secondary[500],
    tertiary: defaultTheme.colors.tertiary[500],
    light: defaultTheme.colors.light,
    lightest: defaultTheme.colors.lightest,
    white: defaultTheme.colors.white,
    black: defaultTheme.colors.black,
    success: defaultTheme.colors.success[500],
    error: defaultTheme.colors.error[500],
    custom: []
  },
  fonts: {
    fontFamily: {
      body: 'Arial',
      heading: 'Arial',
      buttons: 'Arial'
    },
    fontSize: {
      xs: '0.75rem',
      sm: '0.875rem',
      base: '1rem',
      lg: '1.125rem',
      xl: '1.25rem',
      '2xl': '1.5rem',
      '3xl': '2.25rem',
      '4xl': '3rem',
      '5xl': '3.5rem'
    }
  },
  nav: {
    backgroundColor: 'primary',
    color: 'light',
    actionBackgroundColor: 'secondary'
  },
  richText: {
    small: {
      fontSize: 10
    },
    pretitle: {
      fontSize: 14
    },
    default: {
      fontSize: 14
    },
    listItem: {
      fontSize: 14
    },
    lead: {
      fontSize: 24
    },
    subtitle: {
      fontSize: 32
    },
    title: {
      fontSize: 48
    },
    super: {
      fontSize: 64
    }
  },
  button: {
    base: {
      borderRadius: 'sm',
      backgroundColor: 'secondary',
      color: 'primary'
    },
    hover: {
      backgroundColor: 'light',
      color: 'secondary'
    }
  },
  card: {
    base: {
      borderRadius: 'sm',
      backgroundColor: 'white',
      color: 'primary'
    },
    hover: {
      backgroundColor: 'light',
      color: 'black'
    }
  },
  option: {
    base: {
      borderRadius: 'sm',
      backgroundColor: 'white',
      color: 'primary'
    },
    badge: {
      backgroundColor: 'secondary',
      color: 'white'
    },
    hover: {
      outline: '2px solid ' + defaultTheme.colors.tertiary[500],
      backgroundColor: 'light',
      color: 'secondary'
    }
  },
  connect: {
    dropzone: {
      backgroundColor: 'transparent',
      color: 'white'
    },
    option: {
      default: {
        backgroundColor: 'white',
        color: 'primary'
      },
      done: {
        backgroundColor: 'light',
        color: 'secondary'
      }
    }
  },
  sort: {
    dropzone: {
      backgroundColor: 'transparent',
      color: 'white'
    },
    option: {
      default: {
        backgroundColor: 'white',
        color: 'primary'
      },
      done: {
        backgroundColor: 'light',
        color: 'secondary'
      }
    }
  },
  dropzone: {
    backgroundColor: 'primary',
    color: 'white'
  },
  dragzone: {
    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.2) 0px 18px 36px -18px inset'
  },
  screen: {
    backgroundColor: 'primary',
    color: 'secondary'
  },
  timer: {
    backgroundColor: 'secondary',
    color: 'white'
  },
  marker: {
    backgroundColor: 'primary',
    color: 'white'
  },
  login: {
    backgroundColor: 'secondary'
  }
}

export const themes = {
  default: theme,
  sint: sintTheme,
  unicef: unicefTheme
}

export default theme
