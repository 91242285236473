export const msToTime = (ms: number): string => {
  const secs = ms % 60
  ms = (ms - secs) / 60
  const mins = ms % 60

  return `${mins >= 10 ? mins : '0' + mins}:${secs >= 10 ? secs : '0' + secs}`
}

// Util function to format ms to seconds, used by Framer Motion delay settings
export const msToSec = (ms: number): number => {
  if (typeof ms == 'undefined') {
    return 0
  }
  return ms / 1000
}
