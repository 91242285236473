import styled from 'styled-components'

import RichText from '../../RichText'
import { Button } from './../../../Slide/Slide.styled'

const Component = styled.div``

const Actions = styled.div`
  margin-top: 2rem;
`

export interface IIntro {
  intro: {
    title: string
    button?: string
  }
  onStartCheckout: () => void
  isPreview?: boolean
}

const CheckoutIntro = ({ intro, onStartCheckout, isPreview }: IIntro) => {
  return (
    <Component>
      {intro?.title && (
        <RichText
          align='center'
          content={[
            {
              format: 'subtitle',
              text: intro.title
            }
          ]}
        />
      )}
      <Actions>
        <Button
          disabled={isPreview}
          onClick={onStartCheckout}
        >
          {intro?.button || 'Start evaluatie'}
        </Button>
      </Actions>
    </Component>
  )
}

export default CheckoutIntro
