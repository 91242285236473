import { useContext } from 'react'

import SocketContext from '../contexts/socket'
import type { ISocketContext } from './useSocketContextProvider'

const useSocket = (): ISocketContext => {
  return useContext(SocketContext)
}

export default useSocket
