// import { css } from '@styled-system/css'
import styled from 'styled-components'

export const Input = styled.input`
    ${props => ({
    display: 'block',
    border: `1px solid ${props.theme.colors.grey?.[400]}`,
    borderRadius: props.theme.borderRadii['default'],
    width: '100%',
    padding: '8px 8px',
    height: props.theme.spacing['10'],
    fontFamily: props.theme.fonts.fontFamily.body,
    fontSize: props.theme.fonts.fontSize['lg'],
    '&:active,&:focus': {
      borderColor: props.theme.colors.primary[500],
      outlineColor: props.theme.colors.primary[500]
    }
  })}

    &[type="radio"],
    &[type="checkbox"] {
      display: inline-block;
      width: auto;
      height: auto;
    }
`

export default Input
