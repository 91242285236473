import { Button } from '@pidk/renderer/src/components/Slide/Slide.styled'
import useRendererContext from '@pidk/renderer/src/hooks/useRendererContext'
import useSocket from '@pidk/renderer/src/hooks/useSocket'
import { setIsCheckingOut } from '@pidk/web/src/utils/checkout'
import styled from 'styled-components'

const Statistic = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  line-height: 1;
  margin-bottom: 4rem;
`
const Number = styled.strong`
  font-size: 8rem;
  font-weight: bold;
`
const Label = styled.span`
  font-size: 1rem;
  font-weight: bold;
`

const Component = styled.div`
  position: relative;
`

interface ICheckoutWait {
  onDone: () => void
}

const CheckoutWait = ({ onDone }: ICheckoutWait) => {
  const { onRoomClearLastInteraction } = useRendererContext()
  const socketContext = useSocket()

  const amountAnswers = socketContext.state.room?.participants.reduce((output: number, p: any) => {
    let count = 0

    p.interactions.forEach((i: any) => {
      if (i.interactionId === socketContext.state.room?.lastInteraction?.interactionId) {
        count += 1
      }
    })

    return output + count
  }, 0)

  const handleOnCloseClick = async () => {
    await onRoomClearLastInteraction(socketContext.state.socket?.id, socketContext.state.room?.code)
    setIsCheckingOut(false)
    onDone()
  }

  return (
    <Component>
      <Statistic>
        <Number>{amountAnswers}</Number>
        <Label>evaluaties binnen</Label>
      </Statistic>
      <Button onClick={handleOnCloseClick}>
        Afronden
      </Button>

    </Component>
  )
}

export default CheckoutWait
