import generateClamp from '@pidk/common/src/utils/clamp'
import { FieldType } from '@pidk/compose/src/types/fields'
import type { IFieldSchema } from '@pidk/compose/src/types/fields'
import { motion } from 'framer-motion'
import get from 'lodash/get'
import type { CSSProperties } from 'react'
import { useEffect, useState, useRef } from 'react'
import type { CSSObject } from 'styled-components'
import styled, { css } from 'styled-components'
/*
META TODO:
- add markdown for bold, em, linebreaks, or accent color
- perhaps override color per item
- reveal one-by-one
*/

// Probably needed in the default deck Theme

type BlockComponent = React.FC<IRichText> & {
  schema: IFieldSchema
  Styled?: any
}

type StyleFunction<T> = (scale: number) => T;
type TextVariant = {
  [key: string]: StyleFunction<CSSProperties>
}

const textVariants: TextVariant = {
  small: (scale = 10) => ({
    fontSize: generateClamp(scale, 1.75 * scale, 320, 1920),
    lineHeight: 1.5
  }),
  default: (scale = 14) => ({
    fontSize: generateClamp(scale, 1.75 * scale, 320, 1920),
    lineHeight: 1.5
  }),
  pretitle: (scale = 14) => ({
    fontSize: generateClamp(scale, 1.4 * scale, 320, 1920),
    lineHeight: 1.5
  }),
  listItem: (scale = 14) => ({
    fontSize: generateClamp(scale, 1.75 * scale, 320, 1920),
    lineHeight: 1.25
  }),
  lead: (scale = 24) => ({
    fontSize: generateClamp(scale, 1.667 * scale, 320, 1920),
    lineHeight: 1.25
  }),
  subtitle: (scale = 32) => ({
    fontSize: generateClamp(scale, 1.5 * scale, 320, 1920),
    fontWeight: 'bold',
    fontFamily: 'fonts.fontFamily.heading',
    lineHeight: 1
  }),
  title: (scale = 48) => ({
    fontSize: generateClamp(scale, 1.33 * scale, 320, 1920),
    fontWeight: 'bold',
    fontFamily: 'fonts.fontFamily.heading',
    lineHeight: 1
  }),
  super: (scale = 64) => ({
    fontSize: generateClamp(scale, 1.875 * scale, 320, 1920),
    fontWeight: 'bold',
    fontFamily: 'fonts.fontFamily.heading',
    lineHeight: 1
  })
}

// TODO make it possible to extend the variants from theme, fo example default styling
const getTextVariant = (variant: TextFormats, theme) => {
  const scale = parseInt(theme?.richText?.[variant]?.fontSize) || undefined
  const textVariant = !variant ? textVariants['default'](scale) : textVariants[variant](scale)
  textVariant.fontFamily = get(theme, textVariant.fontFamily)
  return textVariant
}

// FIXME: somehow keyof typeof textVariants didn't really play nicely
type TextFormats = 'small' | 'pretitle' | 'default' | 'subtitle' | 'lead' | 'listItem' | 'title' | 'super'

export const TextFormatOptions = Object.keys(textVariants).map(t => { return { value: t, label: t } })

export const formatText = (text: string): any => {
  const bold = new RegExp(/\*\*(.*?)\*\*/gm)
  const italic = /\*(.*?)\*/gm
  const accent = new RegExp(/\#\#(.*?)\#\#/gm)

  // TODO: find a way to make JSX components for styledComponents
  let output = text?.replace(bold, '<strong>$1</strong>')
  output = output?.replace(italic, '<em>$1</em>')
  output = output?.replace(accent, '<span class="accent">$1</span>')
  output = output?.replace('||', '<br />')
  output = output?.replace(/\n/g, '<br />')

  return output
}

export interface ITextString {
  text: string
  format: TextFormats
}

interface IRichText {
  isEditable?: boolean
  content?: ITextString[]
  align?: 'left' | 'center' | 'right'
  visibleComps?: number
}

interface IStyledComponent {
  $format: TextFormats
}

const Component = styled(motion.span) <IStyledComponent>`
    display: block;
    margin-bottom: ${({ theme }) => theme.spacing[2]};

    ${({ $format, theme }) => getTextVariant($format, theme) as CSSObject}

    & + & {
      margin-top: ${({ theme }) => theme.spacing[1]};
    }

    ${({ $format, theme }) => $format === 'listItem' && css`
        padding-left: ${theme.spacing[6]};
        position: relative;

        &::before {
          display: 'block';
          content: '- ';
          position: absolute;
          left: 0;
        }
    `}
`

const Block = styled.div<any>`
  display: flex;
  width: 100%;
  flex-direction: column;
  text-align: ${props => props.align || 'left'};
  padding: ${({ theme }) => `${theme.spacing[3]} 0`};

  > * {
    flex: 1;
  }
`

const Text: BlockComponent = ({
  isEditable = false,
  content,
  align,
  visibleComps = 1000
}: IRichText) => {

  const blockRef = useRef(null)
  const [compsVisible, setCompsVisible] = useState<number>(0)

  const handleClick = () => {
    setCompsVisible(compsVisible + 1)
  }

  useEffect(() => {
    if (visibleComps) {
      // TODO Hacky fix
      const newCompProps = (typeof visibleComps === 'string' ? parseInt(visibleComps) : visibleComps)
      setCompsVisible(newCompProps)
    }
  }, [visibleComps])

  // check for clicks on the whole screen
  useEffect(() => {
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [compsVisible, handleClick])

  return (

    <Block
      ref={blockRef}
      align={align}
    >
      {content?.map((c, i) => (
        <Component
          key={c.format + '' + i}
          $format={c.format}
          initial={!isEditable && visibleComps !== 1000 && compsVisible < i ? { y: 20, opacity: 0 } : undefined}
          animate={!isEditable && visibleComps !== 1000 && compsVisible > i ? { y: 0, opacity: 1 } : undefined}
          transition={!isEditable && compsVisible === 1000 ? { delay: 1 + 0.2 * i, ease: 'easeInOut' } : { delay: 0, ease: 'easeInOut' }}
          dangerouslySetInnerHTML={{ __html: formatText(c.text) }}
        />

      ))}
    </Block>
  )
}

Text.schema = {
  name: 'Rich Text',
  key: 'text',
  defaultFieldValues: {
    align: 'left',
    content: [
      {
        text: 'Dit is een titel',
        format: 'title'
      }, {
        text: 'En een stukje tekst',
        format: 'default'
      }
    ]
  },
  fields: [
    {
      key: 'align',
      type: FieldType.CHOICE,
      label: 'Align',
      options: [
        {
          value: 'left',
          label: 'Left'
        },
        {
          value: 'center',
          label: 'Center'
        },
        {
          value: 'right',
          label: 'Right'
        }
      ]
    },
    {
      key: 'visibleComps',
      type: FieldType.NUMBER,
      label: 'Comps visible',
      instructions: 'Choose a starting point'
    },
    {
      key: 'content',
      type: FieldType.RICHTEXT,
      label: 'Content',
      instructions: 'Bold (**...**), Italic (*...*), Accent (##...##)'
    }
  ]
}

Text.Styled = Component

export default Text
