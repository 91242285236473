import type { FieldValues } from './compose'

export enum FieldType {
  NUMBER = 'NUMBER',
  TEXT = 'TEXT',
  BOOLEAN = 'BOOLEAN',
  IMAGE = 'IMAGE',
  MEDIA = 'MEDIA',
  REPEATER = 'REPEATER',
  GROUP = 'GROUP',
  CHOICE = 'CHOICE',
  COLOR = 'COLOR',
  COLORS = 'COLORS',
  SLIDE = 'SLIDE',
  FONTS = 'FONTS',
  RANGE = 'RANGE',
  RICHTEXT = 'RICHTEXT',
  COORDINATES = 'COORDINATES'
}

interface FieldConditional {
  field: string
  value: string
  operator: string
}
export interface FieldBase {
  key: string
  label: string
  type: FieldType
  primaryKey?: string
  conditional?: FieldConditional[]
  prefix?: string
  appendix?: string
  instructions?: string
}

export enum MediaType {
  IMAGE = 'IMAGE',
  AUDIO = 'AUDIO',
  DOC = 'DOC'
}

export interface FieldNumber extends FieldBase {
  type: FieldType.NUMBER
  step?: number
  min?: number | null
  max?: number | null
}
export interface FieldText extends FieldBase {
  type: FieldType.TEXT
  defaultValue?: string
}

export interface FieldBoolean extends FieldBase {
  type: FieldType.BOOLEAN
}

export interface FieldColor extends FieldBase {
  type: FieldType.COLOR
  hideDefault?: boolean
}

export interface FieldColors extends FieldBase {
  type: FieldType.COLORS
}

export interface FieldImage extends FieldBase {
  type: FieldType.IMAGE
}

export interface FieldFonts extends FieldBase {
  type: FieldType.FONTS
}

export interface FieldMedia extends FieldBase {
  type: FieldType.MEDIA
  mediaType: MediaType
  maxFiles: number
}

export interface FieldChoice extends FieldBase {
  type: FieldType.CHOICE
  component?: 'select' | 'button'
  options: {
    value: string
    label: string
  }[]
}

export interface FieldCoordinates extends FieldBase {
  type: FieldType.COORDINATES
}

export interface FieldSlide extends FieldBase {
  type: FieldType.SLIDE
}

export interface FieldGroup extends FieldBase {
  type: FieldType.GROUP
  collapsible?: boolean
  fields: Field[]
}

export interface FieldRepeater extends FieldBase {
  type: FieldType.REPEATER
  sortable?: boolean
  collapsible?: boolean
  fields: Field[]
  button?: string
}
// TODO: improve typing when options are provided, min, max and step are optional otherwise always required
export interface FieldRange extends FieldBase {
  type: FieldType.RANGE
  min?: number
  max?: number
  step?: number
  options?: string[]
}

export interface FieldRichText extends FieldBase {
  type: FieldType.RICHTEXT
}

export type Field = FieldNumber | FieldFonts | FieldText | FieldRepeater | FieldBoolean | FieldGroup | FieldChoice | FieldColor | FieldColors | FieldImage | FieldMedia | FieldSlide | FieldRange | FieldRichText | FieldCoordinates

export interface FieldComponentProps<T, R> {
  field: T
  value: R
  onFieldValueChange: (field: T, newFieldValue: R) => void
}

export type FieldComponent<T, R> = React.FC<FieldComponentProps<T, R>>

export interface IFieldSchema {
  name: string
  key: string
  fields: Field[]
  defaultFieldValues: FieldValues
}
