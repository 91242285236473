import type { EventFormatted, IEventCreateBody } from '@pidk/api/src/types/api'
import type { Project } from '@pidk/compose/src/types/compose'
import type { Dispatch, SetStateAction } from 'react'
import { createContext } from 'react'

import type { IProjectLoginFormFields } from '../components/ProjectLogin/ProjectLoginForm'
import type { RendererView } from '../types/slide'

export type OnNavigate = (view: RendererView, deckId: string, slideId: string) => void
export type OnRoomCreate = (hostSocketId: string, projectId: string, code?: string) => Promise<string>
export type OnRoomCreateInteraction = (hostSocketId: string, code: string, interactionType: string, interactionData: any) => Promise<any>
export type OnRoomGetInteractionResults = (hostSocketId: string, code: string, interactionId: string) => Promise<any>
export type OnRoomDeactivate = (hostSocketId: string, code: string) => Promise<any>
export type OnRoomActivate = (hostSocketId: string, code: string) => Promise<any>
export type OnRoomClearLastInteraction = (hostSocketId: string, code: string) => Promise<any>
export type OnProjectLogin = (values: IProjectLoginFormFields) => void | Promise<any>
export type OnEventCreate = (body: IEventCreateBody) => Promise<EventFormatted>
export type OnScopedEventCreateBody = Omit<IEventCreateBody, 'projectId' | 'sessionId'>
export type OnScopedEventCreate = (body: OnScopedEventCreateBody) => Promise<EventFormatted>

export interface RendererState {
  eventSessionId?: string
  isAuthenticated: boolean
  hasStarted: boolean
  isConnected: boolean
  hasAskedForStartLesson: boolean
  checkoutCallback?: () => Promise<void>
}

export interface IRendererContext {
  isLoading?: boolean
  isAuthenticated?: boolean
  isPreview?: boolean
  onNavigate: OnNavigate
  project: Project
  currentDeckId: string
  currentSlideId: string
  isEditable: boolean
  onAreaClick?: (id: string) => void
  selectedAreaId?: string
  onError: (error: any) => void
  socketUrl?: string
  setProjectState?: Dispatch<SetStateAction<Project>>
  onProjectLogin?: OnProjectLogin
  onRoomCreate?: OnRoomCreate
  onRoomCreateInteraction?: OnRoomCreateInteraction
  onRoomGetInteractionResults?: OnRoomGetInteractionResults
  onRoomDeactivate?: OnRoomDeactivate
  onRoomActivate?: OnRoomActivate
  onRoomClearLastInteraction?: OnRoomClearLastInteraction
  rendererState: RendererState
  setRendererState: Dispatch<SetStateAction<RendererState>>
  onScopedEventCreate: OnScopedEventCreate
}

const RendererContext = createContext<IRendererContext>(undefined)

export default RendererContext
