import { lazy, Suspense } from 'react'

const VideoPlayer = lazy(() => import('./VideoPlayer'))

// Wrap with Suspense to avoid error
const VideoWrapper = (props) => {
  return (
    <Suspense fallback='test'>
      <VideoPlayer {...props} />
    </Suspense>
  )
}
export default VideoWrapper
