import generateClamp from '@pidk/common/src/utils/clamp'
import { media } from '@pidk/common/src/utils/media'
import type { Properties } from '@pidk/compose/src/types/properties'
import Image from '@pidk/renderer/src/components/Blocks/Image'
import { borderRadii, getBorderRadius } from '@pidk/renderer/src/theme/default'
import { getProjectThemeColor } from '@pidk/renderer/src/utils/project'
import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'

type SlideStyleProps = {
  $colors?: Properties['colors']
  $background?: Properties['background']
  $full?: boolean
}

export const Inner = styled(motion.div) <SlideStyleProps>`
  position: absolute;
  inset: 0;

  margin-top: 7vw;

  ${media.min('mobile')} {
    margin: 4vw;
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(6, 1fr);
  }

  ${({ theme, $colors }) => $colors && css`
    color: ${getProjectThemeColor(theme.colors, ($colors.defaultColor || theme.screen.color))};

    ${$colors.accentColor && css`
      ${Button} {
        background-color: ${getProjectThemeColor(theme.colors, $colors.accentColor)};
        color: white;
      }
    `}
  `}

`

export const Title = styled.h1`
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  margin: 0;
  background-color: ${({ theme }) => getProjectThemeColor(theme.colors, theme.screen.color)};
  color: ${({ theme }) => getProjectThemeColor(theme.colors, theme.screen.backgroundColor)};
  //font-size: ${props => props.theme.fonts.fontSize['lg']};
  font-size: ${generateClamp(20, 24, 320, 1920)};
  padding: 0.25rem 1rem;
  border-radius: 0 0 0.25rem 0.25rem;
  z-index: 20;
`

export const Area = styled.div<any>`
  padding: 4px;
  border-radius: 4px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 24px;

  ${props => props.isEditable && css`
    outline: ${(props.isActive ? '2px solid black' : '1px dashed rgba(0,0,0,0.2)')};
    cursor: pointer;

    > * {
      pointer-events: none;
    }

  `}
  ${props => props.$style && props.$style}


  ${media.max('mobile')} {
    grid-column: span 12!important;
  }
`

export const Button = styled.a<any>`
    flex-grow: 0;
    flex-shrink: 0;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    height: 4rem;
    background-color: ${({ theme }) => getProjectThemeColor(theme.colors, theme.button.base.backgroundColor)};
    color: ${({ theme }) => getProjectThemeColor(theme.colors, theme.button.base.color)};

    border-radius: ${({ theme }) => (theme.button.base.borderRadius && getBorderRadius(theme.button.base.borderRadius) || getBorderRadius('sm'))};
    font-weight: bold;
    border: 0;
    font-size: ${generateClamp(14, 20, 320, 1920)};
    line-height: 1;
    font-family: ${props => props.theme.fonts.fontFamily['buttons']};
    padding: ${({ theme }) => theme.button.base.borderRadius === borderRadii[borderRadii.length - 1] ? '0 2rem' : ' 0 1rem'};
    cursor: pointer;
    text-decoration: none;

    ${media.max('mobile')} {
      height: 3rem;
    }

    svg {
      flex-shrink: 0;
      margin-left: 0.5rem;
    }

    &:hover {
      background-color: ${({ theme }) => getProjectThemeColor(theme.colors, theme.button.hover.backgroundColor)};
      color: ${({ theme }) => getProjectThemeColor(theme.colors, theme.button.hover.color)};
    }
`

export const Logo = styled.div<any>`
  position: absolute;
  top: 0;
  left: 0;
  height: ${({ $size }) => ($size === 'large' ? '12rem' : ($size === 'small' ? '3rem' : '6rem'))};
  width: auto;
  margin: 1rem;

  ${Image.Styled} {
    height: 100%;
    overflow: visible;

    img {
      height: 100%;
      width: 100%;
    }
  }

  ${props => props.$position === 'top-left' && css`
        top: 0;
        left: 0;
  `}

  ${props => props.$position === 'top-right' && css`
        top: 0;
        left: auto;
        right: 0;
  `}

  ${props => props.$position === 'top-center' && css`
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        margin: 16px auto;
  `}
`

interface ISlideBackgroundProps {
  $background: Properties['background']
}
export const Background = styled(motion.figure) <ISlideBackgroundProps>`
  position: absolute;
  inset: 0;
  margin: 0;

  ${({ $background, theme }) => $background && css`
    background-color: ${getProjectThemeColor(theme.colors, ($background.color || theme.screen.backgroundColor))};
    background-image: url('${$background.image}');
    background-size: ${$background.size};
    background-position: ${$background.position};
    background-repeat: ${$background.repeat};

    // TODO: Add this once overlay property is added
    /* &::after {
      content: '';
      display: block;
      position: absolute;
      inset: 0;
      background: ${getProjectThemeColor(theme.colors, ($background.color || theme.screen.backgroundColor))};
      opacity: 0.8;
    } */
  `}

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }


`

const Base = styled(motion.div) <SlideStyleProps>`
  position: absolute;
  inset: 0;
  bottom: ${props => !props.$full ? '7vh' : 0};
  font-family: ${props => props.theme.fonts.fontFamily.body};
  overflow: hidden;
  overflow-y: auto;

  ${props => props.$background && css`
    background-color: ${getProjectThemeColor(props.theme.colors, (props.$background.color || props.theme.screen.backgroundColor))};
    background-image: url('${props.$background.image}');
    background-size: ${props.$background.size};
    background-position: ${props.$background.position};
    background-repeat: ${props.$background.repeat};
  `}


`

export default Base
