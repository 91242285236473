import { useMemo } from 'react'

import { getProjectMedia } from '../utils/project'
import useRendererContext from './useRendererContext'

const useProjectMedia = (mediaIds: string[]) => {
  const { project } = useRendererContext()

  return useMemo(() => {
    return getProjectMedia(project, mediaIds)
  }, [project.media, mediaIds])
}

export default useProjectMedia
