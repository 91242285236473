import generateClamp from '@pidk/common/src/utils/clamp'
import { borderRadii } from '@pidk/renderer/src/theme/default'
import type { ComponentCSSTheme } from '@pidk/renderer/src/types/slide'
import { getProjectThemeColor } from '@pidk/renderer/src/utils/project'
import styled, { css } from 'styled-components'

// Probably needed in the default deck Theme
// TODO how to get real theme values here, not specific to the element
const baseTheme: ComponentCSSTheme = {
  base: {
    borderRadius: borderRadii[2],
    backgroundColor: 'white',
    color: 'primary'
  },
  badge: {
    backgroundColor: 'secondary',
    color: 'white'
  },
  hover: {
    backgroundColor: 'light',
    color: 'black'
  }
}

export const Option = styled.button<any>`
    position: relative;
    border: 0;
    width: 100%;

   ${({ theme }) => css`
        padding: 0;
        outline: 2px solid transparent;
        border-radius: ${theme.option?.base.borderRadius || baseTheme.base.borderRadius};
        background-color: ${getProjectThemeColor(theme.colors, theme.option?.base.backgroundColor)};
        color: ${getProjectThemeColor(theme.colors, theme.option?.base.color)};
        font-family: ${props => props.theme.fonts.fontFamily['buttons']};

        &:disabled {
          pointer-events: none;
        }

        &:not(disabled):hover {
            cursor: pointer;
            background-color: ${getProjectThemeColor(theme.colors, theme.option?.hover.backgroundColor || baseTheme.hover.backgroundColor)};
            color: ${getProjectThemeColor(theme.colors, theme.option?.hover.color || baseTheme.hover.backgroundColor)};
        }

        .badge {
            position: absolute;
            top: -1rem;
            left: -0.5rem;
            min-height: 2rem;
            border-radius: 2rem;
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: 1;
            padding: 0 ${theme.spacing[4]};
            background-color: ${getProjectThemeColor(theme.colors, theme.option?.badge?.backgroundColor)};
            color: ${getProjectThemeColor(theme.colors, theme.option?.badge?.color)};
            transition: all 200ms ease-in-out;
        }

        .thumbnail {
          position: absolute;
          bottom: -20px;
          transform: translateX(-50%);
          left: 50%;
          width: 80px;
          height: 80px;
          margin: 0;
          border-radius: 80px;
          box-shadow: ${({ theme }) => theme.shadows.xl};
          background: white;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .votes {
            position: absolute;
            top: -1rem;
            right: -0.5rem;
            height: 2rem;
            border-radius: 2rem;
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: 1;
            padding: 0 ${theme.spacing[4]};
            color: ${getProjectThemeColor(theme.colors, theme.option?.badge?.backgroundColor)};
            border: 1px solid white;
            background-color: ${getProjectThemeColor(theme.colors, theme.option?.badge?.color)};
            transition: all 200ms ease-in-out;
        }

        .label {
            display: flex;
            align-items: center;
            padding: 0 ${theme.spacing[3]};
            justify-content: flex-start;
            text-align: left;
            font-size: ${theme.fonts.fontSize.lg};
            font-weight: bold;
            font-size: ${generateClamp(14, 24, 320, 1920)};
            line-height: 1;
            height: ${theme.spacing[20]};
        }
`}

    ${props => props.$isSelected && css`
          box-shadow: 0 0 0px 4px ${getProjectThemeColor(props.theme.colors, props.theme.option?.badge?.backgroundColor)};

          .badge {
            transition: all 200ms ease-in-out;
          }
    `}

    ${props => props.$isCorrect && (props.$state === 'results' || props.$state === 'feedback') && css`
          box-shadow: 0 0 0px 4px ${getProjectThemeColor(props.theme.colors, 'success')};

          .badge {
            background-color: ${getProjectThemeColor(props.theme.colors, 'success')};
            transform: scale(1.1);
            transition: all 200ms ease-in-out;
          }
    `}

    ${props => props.$isSelected && props.$isCorrect === false && (props.$state === 'results' || props.$state === 'feedback') && css`
          .badge {
            background-color: ${getProjectThemeColor(props.theme.colors, 'error')};
          }
    `}

`

export const Image = styled.figure`
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
  margin: 0;

  ${({ theme }) => css`
    border-radius: ${theme.option?.base.borderRadius || baseTheme.base.borderRadius};
  `}

  &::after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0; left: 0;
  }
`

export const OptionGrid = styled.div<any>`
  display: flex;
  flex-direction: ${props => (props.$layout === 'horizontal' ? 'row' : 'column')};
  justify-content: flex-start;
  width: 100%;
  gap: 3rem;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    align-items: center;
  }

  ${Option} {
      flex: 1 1 auto;
      max-width: ${props => props.$type === 'text' ? 'auto' : '250px'};
  }
`

export default Option
