import type { MediaFormatted } from '@pidk/api/src/types/api'
import { useMemo } from 'react'

import useRendererContext from './useRendererContext'

const useProjectMediaMap = (): Record<string, MediaFormatted> => {
  const { project } = useRendererContext()

  return useMemo(() => {
    const media = project.media

    return media.reduce((acc, media) => {
      acc[media.id] = media
      return acc
    }, {})
  }, [project.media])
}

export default useProjectMediaMap
