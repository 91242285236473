import { FieldType, MediaType } from '@pidk/compose/src/types/fields'
import type { IFieldSchema } from '@pidk/compose/src/types/fields'
import styled, { css } from 'styled-components'

import Image from './../../Blocks/Image/Image'
import RichText from './../../Blocks/RichText/RichText'

type PopupComponent = React.FC<IPopup> & {
  schema: IFieldSchema
}

const ImageWrapper = styled.div`
  /* max-width: 200px; */
  margin: 0 auto;
`
const ContentWrapper = styled.div``

const Base = styled.div<any>`
  display: flex;
  flex-direction: column;
  font-size: 0.8em;
  gap: 4rem;
  padding: 2rem;

  ${({ $align }) => {

    if ($align == 'center') {
      return css`
        flex-direction: column;
        max-width: 600px;
      `
    }

    if ($align == 'right') {
      return css`
        flex-direction: row-reverse;
        max-width: 60vw;
        min-width: 60vw;
        align-items: center;

        > * {
          width: 50%;
          margin: auto;
        }
      `
    }

    if ($align == 'left') {
      return css`
      flex-direction: row;
        max-width: 60vw;
        min-width: 60vw;
        align-items: center;

        > * {
          width: 50%;
          margin: auto;
        }
      `
    }

  }}

`

export interface IPopup {
  align?: 'left' | 'right' | 'center'
  image?: string
  title?: string
  content?: string
}

export const Popup: PopupComponent = ({ align, image, content, title }: IPopup) => {
  return (
    <Base $align={align}>
      {image && <ImageWrapper><Image image={image} /></ImageWrapper>}
      {/* TODO: Cleanup and pull from RichText */}
      <ContentWrapper>
        {title && (
          <RichText
            align={(align === 'center' ? 'center' : 'left')}
            content={[{
              format: 'subtitle',
              text: title
            }]}
          />
        )}

        {content && (
          <RichText
            align={(align === 'center' ? 'center' : 'left')}
            content={[{
              format: 'default',
              text: content.toString() // TODO temp hack for the content
            }]}
          />
        )}
      </ContentWrapper>
    </Base>
  )
}

Popup.schema = {
  name: 'Popup',
  key: 'popup',
  defaultFieldValues: {
  },
  fields: [
    {
      key: 'image',
      type: FieldType.MEDIA,
      label: 'Image',
      maxFiles: 1,
      mediaType: MediaType.IMAGE
    },
    {
      key: 'title',
      type: FieldType.TEXT,
      label: 'Title'
    },
    {
      key: 'content',
      type: FieldType.TEXT,
      label: 'Content',
      instructions: 'Bold (**...**), Italic (*...*), Accent (##...##)'
    }
  ]
}

export default Popup
