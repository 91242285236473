import VideoPlayer from '@pidk/common/src/components/VideoPlayer'
import ActionsPropSchema from '@pidk/compose/src/schemas/properties/actions'
import { FieldType, MediaType } from '@pidk/compose/src/types/fields'
import type { IFieldSchema } from '@pidk/compose/src/types/fields'
import useProjectMedia from '@pidk/renderer/src/hooks/useProjectMedia'
import type { ReactNode } from 'react'
import styled from 'styled-components'

import useRendererContext from '../../../hooks/useRendererContext'
import { RendererView } from '../../../types/slide'
import type { IPopup } from '../../common/Popup'
import { Popup } from '../../common/Popup'

type BlockComponent = React.FC<IImage> & {
  schema: IFieldSchema
  Styled?: any // @TODO: can we type this?
}

/*
META TODO:
- add margin
- alignment
- fill area or not
- clickable with popup or link to other slide or url
- border
- size
*/

interface IImage {
  image: string
  link?: {
    type: string
    slide?: any
    url?: string
    youtube?: string
    popup?: IPopup
    image?: string
  }
  onPopup?: (type: string, component: ReactNode, timeout?: number, closable?: boolean) => void
}

const ButtonWrapper = styled.button`
  all: unset;
  cursor: pointer;
`
const Component = styled.figure<any>`
  margin: 0;
  overflow: hidden;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`

const Image: BlockComponent = ({
  image,
  link,
  onPopup
}: IImage) => {

  const { onNavigate } = useRendererContext()
  const [blockImage] = useProjectMedia([image])

  const imageComponent = (
    <Component>
      {blockImage ?
        <img src={blockImage?.url} />
        :
        <img src='https://dummyimage.com/400x400/fff/000' />}
    </Component>
  )

  switch (link?.type) {
    case 'link':
      return (
        <a
          href={link.url}
          target='_blank'
          rel='noreferrer'
        >{imageComponent}
        </a>
      )
    case 'slide':
      return (
        <ButtonWrapper onClick={() => onNavigate(RendererView.DECK, link.slide.deck, link.slide.slide)}>{imageComponent}</ButtonWrapper>
      )
    case 'video':
      return (
        <ButtonWrapper onClick={() => onPopup('video',
          <VideoPlayer
            source={link.url}
            youtube={link.youtube}
            controls
            autoPlay
          />
        )}
        >{imageComponent}
        </ButtonWrapper>
      )
    case 'popup':
      return (
        <ButtonWrapper onClick={() => onPopup('text-image',
          <Popup {...link.popup} />
        )}
        >{imageComponent}
        </ButtonWrapper>
      )
    case 'image':
      return (
        <ButtonWrapper onClick={() => onPopup('image',
          <Image image={link.image} />
        )}
        >{imageComponent}
        </ButtonWrapper>
      )
    default:
      return (
        imageComponent
      )
  }

}

Image.schema = {
  name: 'Image',
  key: 'image',
  defaultFieldValues: {
    image: null,
    link: {
      type: 'link'
    }
  },
  fields: [
    {
      key: 'image',
      type: FieldType.MEDIA,
      label: 'Image',
      maxFiles: 1,
      mediaType: MediaType.IMAGE
    },
    {
      key: 'link',
      type: FieldType.GROUP,
      label: 'Link',
      fields: [
        ...ActionsPropSchema.fields
      ]
    }

  ]
}

Image.Styled = Component

export default Image
