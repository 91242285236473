// https://clamp.font-size.app/?config=eyJyb290IjoiMTYiLCJtaW5XaWR0aCI6IjY0MHB4IiwibWF4V2lkdGgiOiIxOTIwcHgiLCJtaW5Gb250U2l6ZSI6IjQwcHgiLCJtYXhGb250U2l6ZSI6IjU0cHgifQ%3D%3D
function toFixed(value: number) {
  return parseFloat(value.toFixed(4))
}

export default function clampBuilder(minFontSize: number, maxFontSize: number, minWidth: number, maxWidth: number): string {
  // if (Object.values(options).some((value) => !value)) {
  //   return '';
  // }
  // // const root = parseInt(options.root, 10);

  // const minFontSize = convertToRem(options.minFontSize, root);
  // const maxFontSize = convertToRem(options.maxFontSize, root);
  // const minWidth = convertToRem(options.minWidth, root);
  // const maxWidth = convertToRem(options.maxWidth, root);

  if ([minFontSize, maxFontSize, minWidth, maxWidth].some((v) => isNaN(v))) {
    return ''
  }

  const slope = (maxFontSize - minFontSize) / (maxWidth - minWidth)
  const yAxisIntersection = toFixed(-minWidth * slope + minFontSize)

  const min = `${minFontSize / 16}rem`
  const max = `${maxFontSize / 16}rem`
  const preferred = `${yAxisIntersection / 16}rem + ${toFixed(slope * 100)}vw`

  return `clamp(${min}, ${preferred}, ${max})`
}
