import { useContext } from 'react'

import type { IRendererContext } from '../contexts/renderer'
import RendererContext from '../contexts/renderer'

const useRendererContext = (): IRendererContext => {
  return useContext(RendererContext)
}

export default useRendererContext
