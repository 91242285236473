import { MobileIcon } from '@pidk/common/src/components/Icons'
import transitions from '@pidk/common/src/lib/transitions'
import type { Dispatch, SetStateAction } from 'react'
import styled, { css } from 'styled-components'
import type { InteractionType } from 'vuurrood-sockets'

import useRendererContext from '../../hooks/useRendererContext'
import useSocket from '../../hooks/useSocket'
import { getCurrentDeck, getProjectThemeColor } from '../../utils/project'
import { ActionButton } from './../common/Actions'

interface IInteractionControls {
  setInteractionResults: Dispatch<SetStateAction<any>>
  createInteractionCallback?: () => void | Promise<void>
  interactionType: InteractionType
  interactionData: any
}

const Badge = styled.div`
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ theme }) => css`
    background-color: ${getProjectThemeColor(theme.colors, theme.option?.badge?.backgroundColor)};
    color: ${getProjectThemeColor(theme.colors, theme.option?.badge?.color)};
  `}
  position: absolute;
  top: -.25rem;
  right: -.25rem;
  font-size: .75rem;
`

const InteractionControls = ({ setInteractionResults, createInteractionCallback, interactionType, interactionData }: IInteractionControls) => {
  const { project, currentDeckId, onRoomCreateInteraction, onRoomGetInteractionResults } = useRendererContext()
  const socketContext = useSocket()
  const currentDeck = getCurrentDeck(project, currentDeckId) as any
  const amountAnswers = socketContext.state.room?.participants.reduce((output: number, p: any) => {
    let count = 0

    p.interactions.forEach((i: any) => {
      if (i.interactionId === socketContext.state.room?.lastInteraction?.interactionId) {
        count += 1
      }
    })

    return output + count
  }, 0)

  const handleCreateInteraction = async (code: string) => {
    setInteractionResults(null)

    await onRoomCreateInteraction(socketContext.state.socket?.id, code, interactionType, interactionData)

    if (createInteractionCallback !== undefined) {
      await createInteractionCallback()
    }
  }

  const handleGetInteractionResults = async (code: string, interactionId: string) => {
    const res = await onRoomGetInteractionResults(socketContext.state.socket?.id, code, interactionId)

    if (res?.interactions !== undefined) {
      const participantInteractions: any[] = res.interactions.reduce((output: any[], { interactionData }: any) => {
        return [...output, ...interactionData]
      }, [])

      setInteractionResults(participantInteractions)
    }
  }
  if (!currentDeck.hasVoting) {
    return null
  }
  if (!socketContext.state.room || !(socketContext.state.room?.status === 'OPEN')) {
    return null
  }

  if (!socketContext.state.room?.lastInteraction) {
    return (
      <ActionButton
        onClick={() => handleCreateInteraction(socketContext.state.room.code)}
        {...transitions.fadeUp}
        transition={{ delay: 1 }}
      >
        <MobileIcon
          width={32}
          height={32}
        />
        <span>Stemmen</span>
      </ActionButton>
    )
  }

  return (
    <ActionButton
      onClick={() => handleGetInteractionResults(socketContext.state.room.code, socketContext.state.room.lastInteraction.interactionId)}
      {...transitions.fadeUp}
      transition={{ delay: 1 }}
    >
      <span>Toon resultaten</span>
      <Badge>
        {amountAnswers}
      </Badge>
    </ActionButton>
  )
}

export default InteractionControls
