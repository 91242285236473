// Make sure to upload the correct files in web/public package
// This file is just for reference

const BASE_PATH = '/sfx/'

const SFX = {
  'click': BASE_PATH + 'click.m4a',
  'correctAlert': BASE_PATH + 'correct_alert.m4a',
  'correctBell': BASE_PATH + 'correct_bell.m4a',
  'negativeAlert': BASE_PATH + 'negative_alert.m4a',
  'negativeBliep': BASE_PATH + 'negative_bliep.m4a',
  'negativeDongs': BASE_PATH + 'negative_dongs.m4a'
}

export default SFX
