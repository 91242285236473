import styled, { css } from 'styled-components'

export const Select = styled.select`
  ${props => css({
  display: 'block',
  border: `1px solid ${props.theme.colors.grey[400]}`,
  borderRadius: props.theme.borderRadii['default'],
  width: '100%',
  padding: '8px 8px',
  height: props.theme.spacing['10'],
  fontFamily: props.theme.fonts.fontFamily.body,
  fontSize: props.theme.fonts.fontSize['lg'],
  textTransform: 'capitalize',
  '&:active,&:focus': {
    borderColor: props.theme.colors.primary[500],
    outlineColor: props.theme.colors.primary[500]
  }
})}
`

export default Select
